
import { orderBy } from 'lodash';
import { reactive, ref, toRefs, watch } from 'vue';
import { FilterItems } from '@/components/kt-datatable/model/filter.dto';
import CardTable from '@/components/kt-datatable/CardTable.vue';
import ApiService from '@/core/services/ApiService';
import { reinitializeComponents } from '@/core/plugins/keenthemes';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { locationHeader } from '../../core/data/location';
import { computed } from '@vue/reactivity';

import { Province } from './dto/province.dto';

export default {
  name: 'NodeLists',
  components: {
    CardTable,
  },
  setup() {
    const loopers = ['no', 'provinceName', 'name', 'latitude', 'longitude'];
    const customProperties = [
      { key: 'provinceName', value: ['province', 'name'] },
    ];
    const tableRef = ref<any>(null);
    const state = reactive({
      loading: true,
      selectedProvince: null,
      province: 'all',
      breadcrumbs: ['Location', ['Node Province']],
      provinceTitle: 'All Province',
      provinces: [] as Province[],
      tableData: [],
    });

    watch(
      () => [tableRef.value?.rowsPerPage, tableRef.value?.currentPage],
      async ([rows, page]) => {
        if (!state.provinces.length) {
          const { data } = await ApiService.get(
            'location/province?pageSize=100'
          );

          state.provinces = orderBy(data.datas, ['name'], ['asc']);

          state.breadcrumbs = ['Location', [state.provinceTitle]];

          setCurrentPageBreadcrumbs(
            'Location',
            [
              {
                name: 'Node Province',
                path: '/location/node-province',
              },
              state.provinceTitle,
            ],
            {}
          );
        }

        const condition = {
          page,
          pageSize: rows,
        };

        const res = await ApiService.get(
          `location/service-point?${new URLSearchParams(condition)}`
        );
        state.tableData = res.data.datas;
        tableRef.value.total = res.data.total;

        reinitializeComponents();
        state.loading = false;
      }
    );

    return {
      ...toRefs(state),
      tableHeader: locationHeader,
      loopers,
      customProperties,
      tableRef,
      filterData: computed(() => {
        const filterData: FilterItems[][] = [];
        const provinceFilter: FilterItems[] = [];
        provinceFilter.push({ name: 'Province', key: 'province' });

        state.provinces.forEach(({ name, id }) => {
          provinceFilter.push({ name, id });
        });

        filterData.push(provinceFilter);

        return filterData;
      }),
    };
  },
};
