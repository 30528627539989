export const locationHeader = [
  {
    name: 'No.',
    key: 'no',
    sortable: true,
  },
  {
    name: 'Province',
    key: 'provinceName',
    sortable: true,
  },
  {
    name: 'Location',
    key: 'name',
    sortable: true,
  },
  {
    name: 'Latitude',
    key: 'latitude',
    sortable: true,
  },
  {
    name: 'Longitude',
    key: 'longitude',
    sortable: true,
  },
  {
    name: 'Actions',
    key: 'actions',
    sortable: false,
  },
];
